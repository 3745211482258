<template>
    <v-container>
      <vg-loading :loading="loading"></vg-loading>
      <v-row justify="center">
        <v-col lg="2" md="3" sm="3" xs="12" class="col-12" >
					<v-select v-model="pagination.limit" outlined label="จำนวนรายการ" dense :items="limit" @change="getStudentCase" hide-details></v-select>
				</v-col>
        <v-col lg="4" class="text-center">
          <v-text-field
            label="ชื่อคดี"
            class="pb-0 mb-0"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            outlined
            clearable
            @change="onChangeSearch"
            hide-details
            dense
            ></v-text-field>
        </v-col>
      </v-row>
      <template>
        <!-- max-width="550" -->
        <v-row justify="center" v-if="student_cases.length!==0">
          <v-col lg="6">
          <v-card class="mx-auto" >
            <v-list three-line>
              <template v-for="(item, index) in student_cases">
                <!-- <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader> -->

                <v-card class="bg-hover elevation-0" :key="index" :to="'/students/'+$route.params.student_id+'/reports/create?caseId='+item._id">
                <v-divider
                  v-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                  style="margin-left: 0px;max-width: 100%;"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="item.title"
                >
                  <v-list-item-avatar>
                    <!-- <v-img :src="item.avatar"></v-img> -->
                    <v-icon class="text--primary icon-bg">{{ item.icon }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title" style="white-space: wrap"></v-list-item-title>
                    <v-list-item-subtitle v-html="'หมวดหมู่ : '+item.subtitle" style="white-space: wrap"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              </template>
            </v-list>
          </v-card>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center" v-if="student_cases.length!==0">
        <v-col lg="6" sm="12">
          <v-pagination v-model="pagination.current" :length="pagination.pages" @input="getStudentCase"></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    mounted() {
      this.getStudentCase();
      this.student_id = this.$route.params.id;
    },
    data() {
      return {
        limit: [10,20,50,100,1000,2000,5000],
        search: "",
        loading: true,
        student_cases: [],
        student_id: null,
        createDialog: false,
        student_case: {
          name: null
        },
        pagination: {
					limit: 10,
					current: 1,
					total: 0,
					pages: 1,
					next_page: 1,
					previous_page: 0,
				},
      };
    },
    methods: {
      getStudentCase() {
        const iconCases =  {
            0:"mdi-book-open-page-variant-outline",
            1:"mdi-tshirt-crew-outline",
            2:"mdi-run-fast",
            3:"mdi-liquid-spot",
        }
        let app = this;
        app.loading = true;
        app.$http
          .get(`https://api-kpsw-case-service.cloudary.dev/api/v1/case?page=${this.pagination.current}&limit=${this.pagination.limit}&search=${this.search}`)
          .then(res => {
            console.log("res.data",res.data.data);

            app.pagination['total']=res.data.meta.total
            app.pagination['pages']=res.data.meta.pages
            app.pagination['current']=res.data.meta.current_page

            app.student_cases = res.data.data.reduce((result,val,index)=>{
              result = result.concat([{
                ...val,
                icon: _.get(iconCases,val.category,0),
                title: `${val.name} ( <lable class="red--text">-${_.get(val,'score',0)}</lable> )`,
                subtitle: `${val.category_name}`,
              }],index+1===res.data.data.length?[]:[{ divider: true, inset: true }])
              return result
            },[]);
            console.log(app.student_cases);
            app.loading = false;
          })
          .catch(err => {
            console.log(err);
            app.loading = false;
          });
      },
      onChangeSearch(val){
        if (_.isNull(val)) {
          this.search=""
        }
        this.getStudentCase()
      }
    },
    computed: {
      filteredItems() {
        return _.orderBy(
          this.student_cases.filter(item => {
            if (!this.search) return this.student_cases;
            return item.name.toLowerCase().includes(this.search.toLowerCase());
          }),
          "name"
        );
      }
    }
  };
  </script>
  
  <style>
  </style>