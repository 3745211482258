
<template>
    <v-container>
      <vg-loading :loading="loading"></vg-loading>
      <v-row justify="center" v-if="!loading">
        <v-col lg="6">
          <v-card>
            <v-container>
              <v-row>
                <v-col class="py-0">
                  <v-subheader>ชื่อ-นามสกุล : {{ student.firstname +" "+ student.lastname }}</v-subheader>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-subheader>เลขประจำตัว : {{ student.student_number }}</v-subheader>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-subheader>กระทำผิดในคดี : {{ student_case.name }}</v-subheader>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-3">
                  <v-menu
                    v-model="datepicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="250px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined v-model="report.date" label="วันที่" readonly dense v-on="on" class="px-4"></v-text-field>
                    </template>
                    <v-date-picker v-model="report.date" locale="th" @input="datepicker = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-subheader>เวลา <vue-timepicker class="pl-2" v-model="report.time" format="HH:mm" close-on-complete></vue-timepicker></v-subheader>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-textarea outlined="" class="px-4" label="รายละเอียดเพิ่มเติม"  v-model="report.detail" dense></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-5 text-left">
                  <v-btn :to="'/student/'+$route.params.student_id+'/student-cases'">ย้อนกลับ</v-btn>
                </v-col>
                <v-col class="pb-5 text-right">
                  <v-btn color="primary" @click="submitForm">ยืนยัน</v-btn>
                </v-col>
              </v-row>
            </v-container>
            <!-- <v-card-title>
              <v-text-field label="ชื่อ นามสกุล" disabled v-model="student.name" dense>test jiijiji</v-text-field>
              <v-text-field label="ชื่อ นามสกุล" disabled v-model="student.name" dense>test jiijiji</v-text-field>
              <v-text-field label="ชื่อ นามสกุล" disabled dense>test jiijiji</v-text-field>
              <v-text-field label="ชื่อ นามสกุล" disabled dense>test jiijiji</v-text-field>
              <v-text-field label="ชื่อ นามสกุล" disabled dense>test jiijiji</v-text-field>
              <v-textarea label="รายละเอียดเพิ่มเติม" dense></v-textarea>
            </v-card-title>-->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    mounted() {
      let app = this;
      let prom = new Promise((resolve, reject) => {
        app.getStudent();
        resolve();
      });
      prom.then(() => {
        app.getStudentCase();
        app.report.student_id = app.$route.params.student_id;
        app.report.student_case_id = app.$route.query.caseId;
      });
    },
    data() {
      return {
        student: {},
        student_case: {},
        loading: true,
        datepicker: null,
        report: {
          date : dayjs().format('YYYY-MM-DD'),
          time : dayjs().format('HH:mm'),
          detail : ""
        }
      };
    },
    methods: {
      submitForm(){
        let app =this;
        app.loading = true;
        // app.report.date = new Date(app.report.date + " " + app.report.time)
        // console.log("app.report",app.report);
        app.$http.post("https://api-kpsw-report-service.cloudary.dev/api/v1/report",{...app.report,datetime:`${app.report.date}T${app.report.time}:00.000Z`})
        .then((res)=>{
          console.log(res.data)
           app.loading = false;
           app.$router.push("/");
        })
        .catch((err)=>{
          console.log(err.response)
           app.loading = false;
        })
      },
      getStudent() {
        let app = this;
        app.loading = true;
        this.$http.get("https://api-kpsw-student-service.cloudary.dev/api/v1/student/" + this.$route.params.student_id).then(res => {
          app.student = res.data;
          console.log(res.data);
          app.loading = false;
        });
      },
      getStudentCase() {
        let app = this;
        app.loading = true;
        this.$http
          .get("https://api-kpsw-case-service.cloudary.dev/api/v1/case/" + this.$route.query.caseId)
          .then(res => {
            app.student_case = res.data;
            console.log(res.data);
            app.loading = false;
          });
      }
    }
  };
  </script>
  
  <style>
  </style>