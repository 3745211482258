<template>
    <v-container>
      <vg-loading :loading="loading"></vg-loading>
      <v-row justify="center" dense>
			<v-col lg="6" sm="12">
				<v-toolbar class="elevation-2" dark>
					<v-toolbar-title>รายชื่อคดีความผิด</v-toolbar-title>
					<v-spacer></v-spacer>
          <div justify="center" v-if="!loading">
            <v-fab-transition>
              <v-dialog v-model="createDialog" persistent max-width="500">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" style="" dark large class="text-right" v-on="on">เพิ่มคดีใหม่</v-btn>
                </template>
                <v-card>
                  <v-card-title>เพิ่มคดีใหม่</v-card-title>
                  <v-card-text>
                    <v-select v-model="student_case.category" dense :items="categoryList" item-text="name" label="หมวดหมู่" class="mt-5"></v-select>
                    <v-text-field label="ชื่อคดี" v-model="student_case.name"></v-text-field>
                    <v-text-field label="คะแนน" v-model.number="student_case.score"></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="createDialog = false">ยกเลิก</v-btn>
                    <v-btn color="green darken-1" text @click="submitForm">ยืนยัน</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-fab-transition>
          </div>
					<!-- <v-btn color="primary" @click.stop="importDialog = true">เพิ่มคดีใหม่</v-btn> -->
				</v-toolbar>
			</v-col>
		</v-row>
      <v-row justify="center">
        <v-col lg="2" md="3" sm="3" xs="12" class="col-12" >
					<v-select v-model="pagination.limit" dense outlined :items="limit" @change="getStudentCase" hide-details label="จำนวนรายการ"></v-select>
				</v-col>
        <v-col lg="4" class="text-center">
          <v-text-field
            label="ชื่อคดี"
            class="pb-0 mb-0"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            dense
            clearable
            @change="onChangeSearch"
            hide-details
            outlined
            ></v-text-field>
            <!-- @click="search=''"
              @click:clear="clickClear"
            @input="getStudentCase" -->
        </v-col>
      </v-row>
      
      
      <template>
        <!-- max-width="550" -->
        <v-row justify="center" v-if="listCases.length!==0">
          <v-col lg="6">
          <v-card class="mx-auto" >
            <v-list three-line>
              <template v-for="(item, index) in listCases">
                <!-- <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader> -->

                <div class="bg-hover" :key="index" @click="selectEdit(item)">
                <v-divider
                  v-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                  style="margin-left: 0px;max-width: 100%;"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="item.title"
                >
                  <v-list-item-avatar>
                    <!-- <v-img :src="item.avatar"></v-img> -->
                    <v-icon class="text--primary icon-bg">{{ item.icon }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title" style="white-space: wrap"></v-list-item-title>
                    <v-list-item-subtitle v-html="'หมวดหมู่ : '+item.subtitle" style="white-space: wrap"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              </template>
            </v-list>
          </v-card>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center" v-if="listCases.length!==0">
        <v-col lg="6" sm="12">
          <v-pagination v-model="pagination.current" :length="pagination.pages" @input="getStudentCase"></v-pagination>
        </v-col>
      </v-row>

              <v-dialog v-if="caseIdEdit!==-1" v-model="modalEdit" persistent max-width="500">
                <!-- <template v-slot:activator="{ on }">
                  <v-btn color="primary" style="" dark large class="text-right" v-on="on">เพิ่มคดีใหม่</v-btn>
                </template> -->
                <v-card>
                  <v-card-title>แก้ไขคดี
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-4" dark @click="confirmDialog=true" tile dense>ลบ</v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-select v-model="student_case.category" dense :items="categoryList" item-text="name" label="หมวดหมู่" class="mt-5"></v-select>
                    <v-text-field label="ชื่อคดี" v-model="student_case.name"></v-text-field>
                    <v-text-field label="คะแนน" v-model.number="student_case.score"></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="caseIdEdit=-1">ยกเลิก</v-btn>
                    <v-btn color="green darken-1" text @click="summitEdit">ยืนยัน</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="confirmDialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="error headline white--text">คุณต้องการลบใช่หรือไม่</v-card-title>

                  <v-card-text class="pt-3">เมื่อดำเนินการแล้ว จะไม่สามารถกู้คืนได้อีก</v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary" @click="confirmDialog=false">ปฏิเสธ</v-btn>

                    <v-btn color="error" @click="deleteCase();confirmDialog=false">ยอมรับ</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
  
      <!-- <v-dialog v-model="createDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">เพิ่มคดีใหม่<v-card-title>
  
        <v-card-text>
            <v-text-field label="ชื่อคดี" v-model="student_case.name"></v-text-field>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
  
          <v-btn color="green darken-1" text @click="createDialog = false">ยกเลิก</v-btn>
  
          <v-btn color="green darken-1" text @click="submitForm">ยืนยัน</v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>-->
    </v-container>
  </template>
  
  <script>

const iconCases =  {
    0:"mdi-book-open-page-variant-outline",
    1:"mdi-tshirt-crew-outline",
    2:"mdi-run-fast",
    3:"mdi-liquid-spot",
}

  export default {
    mounted() {
      this.getStudentCase();
      this.student_id = this.$route.params.id;
    },
    data() {
      return {
        categoryList:[
          {
            name:"การเรียน",
            value:0
          },{
            name:"การแต่งกาย",
            value:1
          },{
            name:"ความประพฤติ",
            value:2
          },{
            name:"การรักษาความสะอาด",
            value:3
          }
        ],
        listCases: [],
        limit: [10,20,50,100,1000,2000,5000],
				pagination: {
					limit: 10,
					current: 1,
					total: 0,
					pages: 1,
					next_page: 1,
					previous_page: 0,
				},

        search: "",
        loading: true,
        student_cases: [],
        student_id: null,
        createDialog: false,
        confirmDialog:false,
        caseIdEdit:-1,
        modalEdit:true,
        student_case: {
          name: null,
          score:null,
          category:-1,
        }
      };
    },
    methods: {
      deleteCase(){
        let app = this;
        app.$http
          .delete("https://api-kpsw-case-service.cloudary.dev/api/v1/case/"+app.caseIdEdit)
          .then(res => {
            app.caseIdEdit=-1
            app.student_case = {
              name: null,
              score:null,
              category:-1,
            }
            this.getStudentCase()
          })
          .catch(err => {
            console.log(err.response);
          });
      },
      selectEdit(val){
        let app = this
        app.student_case = {
          name: val.name,
          score:val.score,
          category:val.category,
        }
        app.caseIdEdit=val._id
      },
      summitEdit(){
        let app = this;
        app.$http
          .put("https://api-kpsw-case-service.cloudary.dev/api/v1/case/"+app.caseIdEdit, app.student_case)
          .then(res => {
            app.caseIdEdit=-1
            app.student_case = {
              name: null,
              score:null,
              category:-1,
            }
            this.getStudentCase()
          })
          .catch(err => {
            console.log(err.response);
          });
      },
      submitForm() {
        this.createDialog = false;
        let app = this;
        app.$http
          .post("https://api-kpsw-case-service.cloudary.dev/api/v1/case", app.student_case)
          .then(res => {
            app.student_case = {
              name: null,
              score:null,
              category:-1,
            }
            this.getStudentCase()
            // app.student_cases.push(res.data)
          })
          .catch(err => {
            console.log(err.response);
          });
      },
      getStudentCase() {
        let app = this;
        app.loading = true;
        app.$http
          .get(`https://api-kpsw-case-service.cloudary.dev/api/v1/case?page=${this.pagination.current}&limit=${this.pagination.limit}&search=${this.search}`)
          .then(res => {

            app.pagination['total']=res.data.meta.total
            app.pagination['pages']=res.data.meta.pages
            app.pagination['current']=res.data.meta.current_page
            app.student_cases = res.data.data;
            app.listCases = res.data.data.reduce((result,val,index)=>{
              result = result.concat([{
                ...val,
                icon: _.get(iconCases,val.category,0),
                title: `${val.name} ( <lable class="red--text">-${_.get(val,'score',0)}</lable> )`,
                subtitle: `${val.category_name}`,
              }],index+1===res.data.data.length?[]:[{ divider: true, inset: true }])
              return result
            },[])
            app.loading = false;
          })
          .catch(err => {
            console.log(err);
            app.loading = false;
          });
      },
      onChangeSearch(val){
        if (_.isNull(val)) {
          this.search=""
        }
        this.getStudentCase()
      }
    },
    computed: {
      filteredItems() {
        return _.orderBy(
          this.student_cases.filter(item => {
            if (!this.search) return this.student_cases;
            return item.name.toLowerCase().includes(this.search.toLowerCase());
          }),
          "name"
        );
      }
    },
    watch:{
      caseIdEdit(newVal){
        let app = this
        if (newVal===-1) {
          app.student_case = {
            name: null, score:null, category:-1,
          }
        }
      }
    }
  };
  </script>
  
  <style>
  .bg-hover{
    cursor: pointer;
  }
  .bg-hover:hover{
    background: #00000011;
  }
  .icon-bg{
    width: 40px;
    height: 40px;
    background-color: antiquewhite;
  }
  </style>