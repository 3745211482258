<template>
	<v-card class="mx-auto" max-width="344">
		<v-card-text class="mb-0 pb-0">
			<v-toolbar-title class="pl-0"> สวัสดี! </v-toolbar-title>
			<p class="name-show text-h4 text--primary">{{ currentUser.name }}</p>
		</v-card-text>
		<v-list disabled>
			<v-subheader>ข้อมูลส่วนตัว</v-subheader>
			<v-list-item-group color="primary">
				<v-list-item v-for="(item, i) in items" :key="i">
					<v-list-item-icon>
						<v-icon v-text="item.icon"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="item.text"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
			<v-divider></v-divider>
		</v-list>
		<v-card-actions>
			<v-btn class="mx-auto" text color="primary" @click="$router.push({ name: 'EditProfile' })"> แก้ไขข้อมูลส่วนตัว </v-btn>
		</v-card-actions>
		<v-card-actions>
			<v-btn block color="primary" @click="$auth.logout()">ออกจากระบบ</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	data: () => ({
		currentUser: {},
		items: [],
	}),
	async mounted() {
		var app = this;
		var user = await app.$auth.fetch();
		app.currentUser = user.data;

		app.items.push({ text: app.currentUser.phone_number, icon: "mdi-cellphone-basic" });
		app.items.push({ text: "มัธยมศึกษาปีที่ " + app.currentUser.grade + " ห้อง " + app.currentUser.class, icon: "mdi-google-classroom" });
		app.items.push({ text: "เลขที่ " + app.currentUser.class_number, icon: "mdi-numeric" });
	},
};
</script>

<style>
.name-show {
	font-family: prompt !important;
}
</style>