import Vue from 'vue';
export const state = {
    data: {
        page: "login",
        token: "",
        transaction_id:"",
        email:""
    }
};

export const mutations = {
    setToken(state, payload) {
        state.data.token = payload;
        axios.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    },
    setPage(state, payload) {
        state.data.page = payload
    },
    setTransaction_id(state, payload) {
        state.data.transaction_id = payload
    },
    setEmail(state, payload) {
        state.data.email = payload
    },
};

export const actions = {
    login(state, payload) {
        return new Promise((resolve, reject) => {
            Vue.auth
                .login({
                    data: payload,
                    rememberMe: payload.rememberMe,
                    fetchUser: true,
                })
                .then(resp => {
                    state.commit('setToken', resp.config.headers.Authorization);
                    resolve(true)
                })
                .catch(err => {
                    reject(err.response)
                });
        })
    },
    logout(state) {
        Vue.auth.logout();
        state.commit("setToken", "");
    },
    setPage(state, payload) {
        state.commit("setPage", payload);
    },
    setTransaction_id(state, payload) {
        state.commit("setTransaction_id", payload);
    },
    setEmail(state, payload) {
        state.commit("setEmail", payload);
    },
};

export const getters = {
    token: state => state.data.token,
    page: state => state.data.page,
    transaction_id: state => state.data.transaction_id,
    email: state => state.data.email,
};
