<template>
    <v-container>
      <vg-loading :loading="loading"></vg-loading>
      <v-row v-if="!loading" justify="center">
        <v-col  lg="6" sm="12">
          <v-card>
            <v-card-title class="black white--text">{{ student.firstname+" "+student.lastname }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-card-subtitle class="py-0">เลขประจำตัว: {{ student.student_number }}</v-card-subtitle>
              <v-card-subtitle class="py-0">ชั้น: {{ student.grade+"/"+student.class }}</v-card-subtitle>
              <v-card-subtitle class="py-0">ครูประจำชั้น: {{ student.teacher_name }}</v-card-subtitle>
              <v-card-subtitle class="py-0">เบอร์โทรผู้ปกครอง: {{ student.parent_contact }}</v-card-subtitle>
              <v-card-subtitle class="py-0">จำนวนคดี: {{ student.case_number }}</v-card-subtitle>
              <v-card-subtitle class="py-0">ระดับความเสี่ยง: {{ getCaseText(student.case_number) }}</v-card-subtitle>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn x-large color="primary" :href="'tel:'+ student.parent_contact">ติดต่อผู้ปกครอง</v-btn>
              <v-spacer></v-spacer>
              <v-btn x-large color="primary" @click="editable = true">แก้ไขข้อมูล</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row></v-row>
      <v-dialog v-model="editable" max-width="500px">
        <v-card>
          <v-card-title class="black white--text">
            แก้ไข
            <v-spacer></v-spacer>
            <v-btn icon class="white--text" @click="editable = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col>
                  <v-text-field v-model="student.firstname" outlined dense label="ชื่อ"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="student.lastname" outlined dense label="นามสกุล"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field v-model="student.grade" outlined dense label="ชั้น"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="student.class" outlined dense label="ห้อง"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field v-model="student.teacher_name" outlined dense label="ครูประจำชั้น"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field v-model="student.parent_contact" outlined dense label="เบอร์โทรผู้ปกครอง"></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="text-right">
                  <v-btn color="primary" @click="putStudent()">ยืนยัน</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  export default {
    mounted() {
      let app = this;
      app._id = app.$route.params.id;
      app.getStudent();
    },
    data() {
      return {
        student: {},
        _id: "",
        loading: true,
        editable : false
      };
    },
    methods: {
      putStudent(){
        let app = this;
        app.editable = false;
        let student = {
          firstname : this.student.firstname,
          lastname : this.student.lastname,
          grade: parseInt(this.student.grade),
          class: parseInt(this.student.class),
          teacher_name : this.student.teacher_name,
          parent_contact : this.student.parent_contact
        }
        app.loading = true;
         app.$http
          .put("http://api-kpsw-student-service.cloudary.dev/api/v1/student/" + app._id,student)
          .then(res => {
            app.getStudent();
            app.loading = false;
            console.log(res.data.data);
          })
          .catch(err => {
            app.loading = false;
            console.log(err.response);
          });
      },
      getStudent() {
        let app = this;
        app.loading = true;
        app.$http
          .get("https://api-kpsw-student-service.cloudary.dev/api/v1/student/" + app._id)
          .then(res => {
            app.student = res.data;
            app.loading = false;
            console.log(res.data);
          })
          .catch(err => {
            app.loading = false;
            console.log(err.response);
          });
      },
      getCaseText(cases, index) {
            return cases > 2 ? "สูง" : cases > 0 ? "ปานกลาง" : "ไม่มี";
        },
    }
  };
  </script>
  
  <style>
  </style>