<template>
	<v-app>
		<v-main>
			<Navbar>
				<router-view />
			</Navbar>
		</v-main>
		<!-- <div v-if="currentRouteName">
			<FooterMenu></FooterMenu>
		</div> -->
	</v-app>
</template>

<script>
import FooterMenu from "./components/FooterMenu.vue";
import Navbar from "./components/Navbar.vue";
export default {
	name: "App",
	components: {
		// FooterMenu,
		Navbar,
	},
	computed: {
		currentRouteName() {
			return this.$route.name !== "Login" && this.$route.name !== "Register" && this.$route.name !== "UMA";
		},
	},
	watch: {
		$route(to, from) {
			if (this.$auth.check()) {
				if(this.$auth.check(['student'])) {
					this.$swal({
						icon : 'warning',
						text : 'ปิดระบบชั่วคราว',
						allowOutsideClick : false
					})
					this.$auth.logout();
				}
				else this.$auth.fetch();
			}
		},
	},
};
</script>

