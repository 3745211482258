<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row align="center" justify="center" v-if="!loading">
			<v-col cols="12" sm="12" lg="7" md="7">
				<v-card>
					<v-toolbar dark>
						<v-toolbar-title>เพิ่มบัญชีผู้ใช้</v-toolbar-title>
					</v-toolbar>
					<v-card-text>
						<v-container>
							<v-form ref="createUser" v-model="createUserValid" lazy-validation>
								<v-row>
									<v-col>
										<v-text-field v-model="user.name" label="ชื่อ-นามสกุล" :rules="rules.name"></v-text-field>
									</v-col>
									<v-col>
										<v-text-field v-model="user.username" label="บัญชีผู้ใช้" :rules="rules.username"></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field v-model="user.department" :rules="rules.department" label="แผนก/ฝ่าย"></v-text-field>
									</v-col>
									<v-col>
										<v-select v-model="user.role" :items="choices.roles" :rules="rules.role" label="บทบาท"></v-select>
									</v-col>
								</v-row>
								<v-row
									><v-col>
										<v-select v-model="user.status" :items="choices.status" :rules="rules.status" label="สถานะ"></v-select> </v-col
								></v-row>
								<v-row>
									<v-col>
										<v-text-field v-model="user.password" type="password" :rules="rules.password" label="รหัสผ่านใหม่"></v-text-field>
									</v-col>
									<v-col>
										<v-text-field v-model="user.confirm_password" type="password" :rules="rules.confirm_password.concat(passwordConfirmationRule)" label="ยืนยันรหัสผ่านใหม่"></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-btn class="grey" @click="$router.push('/users')">ย้อนกลับ</v-btn>
									</v-col>
									<v-col class="text-right">
										<v-btn class="primary" @click="createUser">บันทึก</v-btn>
									</v-col>
								</v-row>
							</v-form>
						</v-container>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar v-model="toast.visible" :timeout="toast.timeout" :color="toast.color" bottom>
			{{ toast.message }}
			<v-btn color="white" text @click="toast.visible = false">ปิด</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
	export default {
		mounted() {},
		data() {
			return {
				_id: null,
				dialog: false,
				createUserValid: false,
				user: {
					name: "",
					username: "",
					role: null,
					status: null,
					department: "",
					password: "",
					confirm_password: "",
				},
				rules: {
					name: [(v) => !!v || "โปรดระบุ"],
					username: [(v) => !!v || "โปรดระบุ"],
					department: [(v) => !!v || "โปรดระบุ"],
					username: [(v) => !!v || "โปรดระบุชื่อผู้ใช้งาน", (v) => (v && v.length >= 6) || "ชื่อผู้ใช้งานต้องมีความยาวอย่างน้อย 6 ตัวอักษร"],
					password: [(v) => !!v || "โปรดระบุรหัสผ่าน", (v) => (v && v.length >= 6) || "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร"],
					confirm_password: [(v) => !!v || "โปรดระบุรหัสผ่านอีกครั้ง", (v) => (v && v.length >= 6) || "รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร"],
					role: [(v) => v !== null || "โปรดระบุ"],
					status: [(v) => v !== null || "โปรดระบุ"],
				},
				loading: false,
				choices: {
					roles: [
						{
							text: "ผู้ดูแลระบบ",
							value: 99,
						},
						{
							text: "ผู้ใช้ทั่วไป",
							value: 0,
						},
					],
					status: [
						{
							text: "รอตรวจสอบ",
							value: 0,
						},
						{
							text: "ตรวจสอบแล้ว",
							value: 1,
						},
					],
				},
				toast: {
					visible: false,
					timeout: 2000,
					color: "error",
					message: "",
				},
			};
		},
		methods: {
			createUser() {
				let app = this;
				this.$refs.createUser.validate();
				if (!this.createUserValid) {
					return;
				}
				this.loading = true;
				app.$http
					.post("https://api-kpsw-user-service.cloudary.dev/api/v1/user", app.user)
					.then((res) => {
						app.toast.color = "success";
						app.toast.message = "บันทึกสำเร็จ";
						app.toast.visible = true;
						// console.log(res.data);
						app.loading = false;
						setTimeout(() => {
							app.$router.push("/users");
						}, 1000);
					})
					.catch((err) => {
						app.toast.color = "error";
						app.toast.message = "ข้อมูลไม่ถูกต้อง";
						app.toast.visible = true;
						// console.log(err.response);
						app.loading = false;
					});
			},
		},
		computed: {
			passwordConfirmationRule() {
				return () => this.user.password === this.user.confirm_password || "รหัสผ่านไม่ตรงกัน";
			},
		},
	};
</script>

<style></style>
