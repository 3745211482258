<template>
	<div class="container-register">
		<v-card-title> แก้ไขข้อมูลส่วนตัว </v-card-title>
		<v-card-subtitle>* จำเป็นต้องระบุ</v-card-subtitle>

		<v-container>
			<v-row>
				<v-col cols="12" md="12">
					<v-text-field label="รหัสผู้ใช้งาน* (ระบุเฉพาะรหัสประจำตัวเท่านั้น)" :error="this.$v.id.$error" :error-messages="this.$v.id.$error ? errorMsgReqID : ''" v-model="id" @keypress="isNumber($event)"></v-text-field>
				</v-col>

				<!-- <v-col cols="12" md="6">
					<v-text-field label="รหัสผ่าน*" :error="this.$v.password.$error" :error-messages="this.$v.password.$error ? errorMsgReqPassword : ''" v-model="password" type="password"></v-text-field>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field label="ยืนยันรหัสผ่าน*" :error="this.$v.confirmPassword.$error" :error-messages="this.$v.confirmPassword.$error ? errorMsgReqconfirmPassword : ''" v-model="confirmPassword" type="password"></v-text-field>
				</v-col> -->

				<v-col cols="12" md="6">
					<v-text-field label="ชื่อ*" :error="this.$v.firstname.$error" :error-messages="this.$v.firstname.$error ? errorMsgReqFirstname : ''" v-model="firstname"></v-text-field>
				</v-col>
				<v-col cols="12" md="6">
					<v-text-field label="นามสกุล*" :error="this.$v.lastname.$error" :error-messages="this.$v.lastname.$error ? errorMsgReqLastname : ''" v-model="lastname"></v-text-field>
				</v-col>

				<v-col cols="12" md="12">
					<v-text-field label="เบอร์ติดต่อ*" :error="this.$v.phone_number.$error" :error-messages="this.$v.phone_number.$error ? errorMsgReqPnumber : ''" v-model="phone_number"></v-text-field>
				</v-col>

				<v-col cols="12" md="4">
					<!-- <v-text-field label="มัธยมศึกษาปีที่*" :error="this.$v.grade.$error" :error-messages="this.$v.grade.$error ? errorMsgReqGrade : ''" type="number" v-model="grade"></v-text-field> -->
					<v-select :error="this.$v.grade.$error" :error-messages="this.$v.grade.$error ? errorMsgReqGrade : ''" v-model="grade" label="ชั้นปี*" :items="dropdown.dropdownGrade" required></v-select>
				</v-col>
				<v-col cols="12" md="4">
					<!-- <v-text-field label="ห้อง*" :error="this.$v.class_room.$error" :error-messages="this.$v.class_room.$error ? errorMsgReqCroom : ''" type="number" v-model="class_room"></v-text-field> -->
					<v-select :error="this.$v.class_room.$error" :error-messages="this.$v.class_room.$error ? errorMsgReqCroom : ''" v-model="class_room" label="ห้อง*" :items="bindClassRoomOption" required></v-select>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field label="เลขที่*" :error="this.$v.class_number.$error" :error-messages="this.$v.class_number.$error ? errorMsgReqCnumber : ''" type="number" v-model="class_number"></v-text-field>
				</v-col>
			</v-row>
		</v-container>
		<v-container>
			<v-row>
				<v-btn class="mx-auto my-4" width="150px" :disabled="this.$v.$error" depressed color="primary" @click="onRegister"> บันทึกข้อมูลส่วนตัว </v-btn>
			</v-row>
			<v-row>
				<v-btn class="mx-auto" width="150px" text color="primary" @click="onReset"> รีเซ็ทข้อมูล </v-btn>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { required, sameAs, numeric, minLength } from "vuelidate/lib/validators";
export default {
	data() {
		return {
			errorMsgReqID: "โปรดกรอกรหัสประจำตัวนักเรียน",
			errorMsgReqPassword: "โปรดกรอกรหัสผ่าน",
			errorMsgReqconfirmPassword: "โปรดกรอกยืนยันรหัสผ่านให้ตรงกับรหัสผ่าน",

			errorMsgReqFirstname: "โปรดกรอกชื่อ",
			errorMsgReqLastname: "โปรดกรอกนามสกุล",

			errorMsgReqGrade: "โปรดกรอกระดับชั้น",
			errorMsgReqCroom: "โปรดกรอกเลขห้องเรียน",
			errorMsgReqCnumber: "โปรดกรอกเลขที่",
			errorMsgReqPnumber: "โปรดกรอกเบอร์ติดต่อ",

			id: "",
			// password: "",
			// confirmPassword: "",

			firstname: "",
			lastname: "",

			grade: null,
			class_room: null,
			class_number: null,
			role: 0,
			phone_number: "",

			submitted: false,

			dropdown: {
				dropdownGrade: [
					{ text: "เลือกชั้นปี", value: -1, disabled: true },
					{ text: "มัธยมศึกษาปีที่ 1", value: 1 },
					{ text: "มัธยมศึกษาปีที่ 2", value: 2 },
					{ text: "มัธยมศึกษาปีที่ 3", value: 3 },
					{ text: "มัธยมศึกษาปีที่ 4", value: 4 },
					{ text: "มัธยมศึกษาปีที่ 5", value: 5 },
					{ text: "มัธยมศึกษาปีที่ 6", value: 6 },
				],
				classes: [
					{ text: "เลือกห้อง", value: null, disabled: true },
					{ text: "1", value: 1 },
					{ text: "2", value: 2 },
					{ text: "3", value: 3 },
					{ text: "4", value: 4 },
					{ text: "5", value: 5 },
					{ text: "6", value: 6 },
				],
			},
		};
	},
	validations: {
		id: { required, numeric, length, minLength: minLength(5) },
		// password: { required },
		// confirmPassword: { required, sameAsPassword: sameAs("password") },
		firstname: { required },
		lastname: { required },

		grade: { required },
		class_room: { required },
		class_number: { required },
		phone_number: { required },
	},
	async mounted() {
		var app = this;
		var user = await app.$auth.fetch();
		// console.log("user.data", user.data);
		app.id = user.data.id;
		// app.password = "",
		// app.confirmPassword = "",
		app.firstname = user.data.firstname;
		app.lastname = user.data.lastname;
		app.grade = user.data.grade;
		app.class_room = user.data.class;
		app.class_number = user.data.class_number;
		app.phone_number = user.data.phone_number;
		app.role = user.data.role;
	},
	computed: {
		bindClassRoomOption() {
			let app = this;
			let dropdownClass = [
				{ text: "เลือกห้อง", value: -1, disabled: true },
				{ text: "ห้อง 1", value: 1 },
				{ text: "ห้อง 2", value: 2 },
				{ text: "ห้อง 3", value: 3 },
				{ text: "ห้อง 4", value: 4 },
				{ text: "ห้อง 5", value: 5 },
				{ text: "ห้อง 6", value: 6 },
				{ text: "ห้อง 7", value: 7 },
				{ text: "ห้อง 8", value: 8 },
				{ text: "ห้อง 9", value: 9 },
				{ text: "ห้อง 10", value: 10 },
				{ text: "ห้อง 11", value: 11 },
				{ text: "ห้อง 12", value: 12 },
				{ text: "ห้อง 13", value: 13 },
				{ text: "ห้อง 14", value: 14 },
			];
			if (app.grade > 0 && app.grade < 4) {
				if (app.class_room > 12) {
					app.class_room = -1;
				}
				return _.filter(dropdownClass, (v) => v.value < 13);
			} else {
				return dropdownClass;
			}
		},
	},
	methods: {
		isNumber: function (evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) || this.id.length > 4) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		onRegister() {
			const app = this;
			this.$v.$touch();
			this.submitted = true;
			var isCheck = !this.$v.$error && this.submitted;
			if (isCheck) {
				this.$swal({
					title: "โปรดยืนยันการเปลี่ยนข้อมูลส่วนตัว",
					reverseButtons: true,
					showCancelButton: true,
				}).then((result) => {
					if (result.isConfirmed) {
						axios
							.put("/api/v1/auth/update", {
								id: this.id,
								firstname: this.firstname,
								lastname: this.lastname,
								grade: this.grade,
								class: this.class_room,
								class_number: this.class_number,
								role: app.role,
								phone_number: this.phone_number,
							})
							.then((res) => {
								this.$swal({
									title: "เปลี่ยนข้อมูลส่วนตัวสำเร็จ",
									icon: "success",
									toast: true,
									timer: 2000,
									showConfirmButton: false,
									timerProgressBar: true,
									position: "top-end",
								});
								this.newPassword = null;
								this.confirmNewPassword = null;
								app.$router.push({ name: "Profile" });
							})
							.catch((err) => {
								this.$swal({
									title: "เปลี่ยนข้อมูลส่วนตัวไม่สำเร็จ",
									icon: "error",
									toast: true,
									timer: 2000,
									showConfirmButton: false,
									timerProgressBar: true,
									position: "top-end",
								});
							});
					}
				});
			}
			// if (isCheck) {
			// 	this.$swal({
			// 		title: "โปรดยืนยันการสมัครสมาชิก",
			// 		reverseButtons: true,
			// 		showCancelButton: true,
			// 	}).then((result) => {
			// 		axios
			// 			.post("/api/v1/auth/register", {
			// 				id: this.id,
			// 				password: this.password,
			// 				firstname: this.firstname,
			// 				lastname: this.lastname,
			// 				grade: this.grade,
			// 				class: this.class_room,
			// 				class_number: this.class_number,
			// 				role: 0,
			// 				phone_number: this.phone_number,
			// 			})
			// 			.then((res) => {
			// 				console.log("res", res);
			// 				if (res.status === 200) {
			// 					app.$swal("สมัครสมาชิกสำเร็จ", "", "success");
			// 					app.$router.push({
			// 						name: "Login",
			// 					});
			// 				} else {
			// 					app.$swal("สมัครสมาชิกไม่สำเร็จ", "", "error");
			// 				}
			// 			})
			// 			.catch((err) => {
			// 				console.log("err", err);
			// 				app.$swal("สมัครสมาชิกไม่สำเร็จ", "", "error");
			// 			});
			// 	});
			// }
		},
		onReset() {
			(this.id = ""), (this.password = ""), (this.confirmPassword = ""), (this.firstname = ""), (this.lastname = ""), (this.grade = ""), (this.class_room = ""), (this.class_number = ""), (this.phone_number = "");
		},
	},
};
</script>

<style>
.container-register {
	margin: 0px auto 0px auto;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 68px;
}
@media (min-width: 600px) {
	.container-register {
		max-width: 400px;
	}
}
@media (min-width: 960px) {
	.container-register {
		max-width: 500px;
	}
}
@media (min-width: 1264px) {
	.container-register {
		max-width: 600px;
	}
}
@media (min-width: 1400px) {
	.container-register {
		max-width: 700px;
	}
}
</style>