<template>
	<!-- $route.name == 'Login    this.$route.name == 'Register' || -->
	<v-container fluid :fill-height="this.$route.name == 'UMA'" class="pa-0">
		<v-navigation-drawer left fixed v-model="drawer" color="primary" temporary width="300" style="top: 0; left: 0">
			<v-list nav dense>
				<v-list-item v-if="user">
					<v-list-item-icon>
						<v-icon class="white--text">mdi-account-circle</v-icon>
				</v-list-item-icon>
					<v-list-item-content>
					<v-list-item-title class="white--text">{{ user.name }}</v-list-item-title>
					<v-list-item-subtitle class="white--text">{{ "ฝ่าย : " + user.department }}</v-list-item-subtitle>
					<v-list-item-subtitle class="white--text">{{ user.roleTitle }}</v-list-item-subtitle>
				</v-list-item-content>
					
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item-group v-model="group" active-class="black--text text--accent-4">
					<v-list-item v-for="(i, j) in listMenuFilter" :key="j" :to="i.path">
						<v-list-item-icon>
							<v-icon class="white--text">{{ i.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="white--text">{{ i.text }}</v-list-item-title>
					</v-list-item>

					<v-list-item @click="$auth.logout()">
						<v-list-item-icon>
							<v-icon class="white--text">{{ "mdi-logout-variant" }}</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="white--text">{{ "ออกจากระบบ" }}</v-list-item-title>
					</v-list-item>

				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar class="navbar" fixed color="primary" dark v-if="currentRouteName">
			<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
			<div
				class="logo-navbar"
				@click="
					if ($route.name !== 'overview') {
						$router.push({ name: 'overview' });
					}
				"
			>
				<v-toolbar-title><span class="font-weight-semibold">ระบบกวดขันงานวินัย</span></v-toolbar-title>
			</div>
		</v-app-bar>

		<div style="margin-top: 70px;">
			<slot></slot>
		</div>
	</v-container>
</template>

<script>
	export default {
		name: "Navbar",
		data: () => ({
			drawer: false,
			group: null,
			listMenu: [
				{
					path: "/",
					icon: "mdi-chart-bar",
					text: "หน้าแรก",
					role: [0, 99],
				},
				{
					path: "/students",
					icon: "mdi-card-account-details",
					text: "รายชื่อนักเรียน",
					role: [0, 99],
				},{
					path: "/reports",
					icon: "mdi-file-table-box-multiple",
					text: "รายการคดีทั้งหมด",
					role: [0, 99],
				},{
					path: "/users",
					icon: "mdi-account-multiple",
					text: "จัดการผู้ใช้งานระบบ",
					role: [99],
				},{
					path: "/add-case",
					icon: "mdi-format-list-text",
					text: "รายชื่อคดี",
					role: [99],
				}
			],
		}),
		computed: {
			user() {
				return this.$auth.user();
			},
			currentRouteName() {
				return this.$route.name !== "Login" && this.$route.name !== "Register" && this.$route.name !== "UMA";
			},
			listMenuFilter() {
				return _.filter(this.listMenu, (item) => this.$auth.check(item.role));
			},
		},
	};
</script>

<style>
	.v-image__image {
		filter: drop-shadow(0 0 0px #fff);
	}
	.logo-navbar {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
	}
</style>
