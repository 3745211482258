<template>
    <v-container>
      <vg-loading :loading="loading"></vg-loading>
      <v-row justify="center" v-if="!loading" dense>
        <v-col lg="6">
          <v-card tile>
            <v-toolbar dark class="elevation-0" dense>
              <v-toolbar-title>
                {{ report.firstname+" "+report.lastname }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="red darken-4" dark @click="deleteReport" tile dense>ลบ</v-btn>
            </v-toolbar>
            <v-card-text class="pa-0">
              <v-list dense>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>กระทำผิดในคดี : {{ report.case_name }}</v-list-item-title>
                    <v-list-item-subtitle>เลขประจำตัว : {{ report.student_number }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-container class="pa-0">
              <v-row dense>
                <v-col class="py-0">
                  <v-menu
                    v-model="datepicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    disabled=""
                    min-width="250px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined v-model="report.date" label="วันที่" readonly  dense v-on="on" class="px-4"></v-text-field>
                    </template>
                    <v-date-picker v-model="report.date" locale="th" @input="datepicker = false"  ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-subheader>เวลา <vue-timepicker  disabled class="pl-2 black--text" v-model="report.time" format="HH:mm" close-on-complete></vue-timepicker></v-subheader>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <v-textarea outlined="" class="px-4" label="รายละเอียดเพิ่มเติม"  v-model="report.detail" dense></v-textarea>
                </v-col>
              </v-row>
              <v-row class="px-5">
                <v-col class="pb-0 text-left">
                  <v-btn :to="'/reports'">ย้อนกลับ</v-btn>
                </v-col>
                <v-col class="pb-5 text-right">
                  <v-btn color="primary" @click="submitForm">ยืนยัน</v-btn>
                </v-col>
              </v-row>
            </v-container>
            </v-card-text>
            
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    mounted() {
      let app = this;
      app._id = app.$route.params.id;
      app.getReport()
    },
    data() {
      return {
        student: {},
        _id : "",
        student_case: {},
        loading: true,
        datepicker: null,
        report: {
          date : new Date().toISOString().substr(0, 10),
          time : new Date().toTimeString().substr(0,5),
          detail:""
        }
      };
    },
    methods: {
      deleteReport(){
        let app = this;
        // app.loading = true;
        app.$http.delete("https://api-kpsw-report-service.cloudary.dev/api/v1/report/"+app._id)
        .then((res)=>{
          console.log(res.data)
          // app.loading = false;
          app.$router.push("/reports");
        })
      },
      submitForm(){
        let app =this;
        app.loading = true;
        app.$http.put("https://api-kpsw-report-service.cloudary.dev/api/v1/report/"+app._id,{...app.report,datetime:`${app.report.date}T${app.report.time}:00.000Z`})
        .then((res)=>{
          console.log(res.data)
           app.loading = false;
           app.$router.push("/");
        })
        .catch((err)=>{
          console.log(err.response)
           app.loading = false;
        })
      },
      getReport(){
          let app =this;
          app.loading = true;
          app.$http.get("https://api-kpsw-report-service.cloudary.dev/api/v1/report/"+app._id)
          .then((res)=>{
              app.report = res.data
              console.log("res.data.data",res);
              app.loading = false;
          }) 
      },
    //   getStudent() {
    //     let app = this;
    //     app.loading = true;
    //     this.$http.get("https://api-kpsw-student-service.cloudary.dev/api/v1/student/" + this.$route.params.student_id).then(res => {
    //       app.student = res.data.data;
    //       console.log(res.data);
    //       app.loading = false;
    //     });
    //   },
    //   getStudentCase() {
    //     let app = this;
    //     app.loading = true;
    //     this.$http
    //       .get("https://api-kpsw-case-service.cloudary.dev/api/v1/case/" + this.$route.query.caseId)
    //       .then(res => {
    //         app.student_case = res.data;
    //         console.log(res.data);
    //         app.loading = false;
    //       });
    //   }
    }
  };
  </script>
  
  <style>
  </style>