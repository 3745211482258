<template>
	<v-container>
		<vg-loading :loading="loading"></vg-loading>
		<v-row align="center" justify="center" v-if="!loading">
			<v-col cols="12" sm="12" lg="6">
				<v-card class="elevation-0">
					<v-toolbar dark>
						<v-toolbar-title>ข้อมูลผู้ใช้งานระบบ</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn dense outlined to="/users/create"> <v-icon>mdi-plus</v-icon>สร้างผู้ใช้งานระบบ </v-btn>
					</v-toolbar>
					<v-card-text class="px-0 py-1">
						<v-row dense>
							<v-col md="4" sm="3" cols="12">
								<v-select v-model="pagination.limit" solo dense :items="limit" @change="pagination.current = 1; getUsers()" hide-details></v-select>
							</v-col>
							<v-col md="8" sm="9" cols="12">
								<v-text-field label="ค้นหา" class="pb-0 mb-0" prepend-inner-icon="mdi-magnify" solo dense hide-details="" v-model="search" clearable @change="pagination.current = 1; getUsers()"></v-text-field>
							</v-col>
						</v-row>
						<v-list three-line max-height="500px" class="overflow-y-auto">
							<template v-for="(item, index) in users">
								<v-list-item :key="item._id" :to="'/users/edit/' + item._id">
									<v-list-item-avatar>
										<v-icon large>mdi-account-circle</v-icon>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title>{{ item.name }} ({{ item.role_name }})</v-list-item-title>
										<v-list-item-subtitle>ฝ่าย : {{ item.department }}</v-list-item-subtitle>
										<v-list-item-subtitle>ชื่อบัญชี : {{ item.username }}</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-icon>
										<v-chip :class="item.status ? 'green lighten-5' : 'red lighten-5'">{{ item.status_name }}</v-chip>
									</v-list-item-icon>
								</v-list-item>
								<v-divider :key="index"></v-divider>
							</template>
						</v-list>
						<v-pagination v-model="pagination.current" :length="pagination.pages" @input="getUsers"></v-pagination>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		data() {
			return {
				users: [],
				loading: true,
				search: "",
				limit: [10, 20, 50, 100, 1000, 2000, 5000],
				pagination: {
					limit: 10,
					current: 1,
					total: 0,
					pages: 1,
					next_page: 1,
					previous_page: 0,
				},
			};
		},
		mounted() {
			let app = this;
			this.getUsers();
		},
		filters: {
			roleName(v) {
				return v === 99 ? "ผู้ดูแลระบบ" : "ผู้ใช้ทั่วไป";
			},
			statusName(v) {
				return v === 1 ? "ตรวจสอบแล้ว" : "รอตรวจสอบ";
			},
		},
		methods: {
			getUsers() {
				let app = this;
				this.$http.get(`https://api-kpsw-user-service.cloudary.dev/api/v1/user?page=${this.pagination.current}&limit=${this.pagination.limit}&search=${this.search}`).then((res) => {
					// console.log(res.data.data);
					app.users = res.data.data;
					app.pagination.current = res.data.meta.current_page;
					app.pagination.total = res.data.meta.total;
					app.pagination.pages = res.data.meta.pages;
					app.loading = false;
				});
			},
		},
	};
</script>

<style></style>
