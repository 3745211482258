import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueAxios from "vue-axios";
import axios from "axios";
import Vuelidate from "vuelidate";

import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js";
import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.esm.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";

// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
// 	config: { id: process.env.VUE_APP_GTAG_KEY || 'G-4MT8VR3RT1'}
//   });

import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/th"; // import locale
import timezone from "dayjs/plugin/timezone";
import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(buddhistEra);
dayjs.tz.setDefault("Asia/Bangkok");
dayjs.locale("th");
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);

import VueTimepicker from 'vue2-timepicker'
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import 'vue2-timepicker/dist/VueTimepicker.css';

let options = {
    confirmButtonColor: "#3498DB",
    cancelButtonColor: "#BDC3C7",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
};

Vue.use(VueSweetalert2, options);

Vue.config.productionTip = false;

Vue.use(auth, {
    plugins: {
        http: axios, // Axios
        router: router,
    },
    drivers: {
        auth: {
            request: function(req, token) {
                this.drivers.http.setHeaders.call(this, req, {
                    Authorization: "Bearer " + token,
                });
            },
            response: function(res) {
                var headers = this.drivers.http.getHeaders.call(this, res),
                    token = headers.Authorization || headers.authorization;
                if (token) {
                    token = token.split(/Bearer:?\s?/i);
                    return token[token.length > 1 ? 1 : 0].trim();
                } else {
                    return res.data.token
                }
            },
        },
        http: driverHttpAxios,
        router: driverRouterVueRouter,
    },
    options: {
        rolesKey: "role",
        authRedirect: { path: "/login" },
        // forbiddenRedirect: function (transition) {
        // 	return "/";
        // },
        // notFoundRedirect: function (transition) {
        // 	return "/404";
        // },
        loginData: { url: "https://api-kpsw-auth-service.cloudary.dev/api/v1/auth/login", method: "POST", redirect: "/", fetchUser: true, staySignedIn: true },
        logoutData: { url: "https://api-kpsw-auth-service.cloudary.dev/api/v1/auth/logout", method: "POST", redirect: "/login", makeRequest: true },
        fetchData: { url: "https://api-kpsw-auth-service.cloudary.dev/api/v1/auth/me", method: "GET", enabled: true },
        refreshData: { url: "https://api-kpsw-auth-service.cloudary.dev/api/v1/auth/refresh", method: "GET", enabled: false, interval: 30 },
        parseUserData(res) {

            return {...res, roleTitle: res.role === 0 ? 'ผู้ใช้ทั่วไป' : 'ผู้ดูแลระบบ' };
        },
    },
});

const handleResponseError = (error) => {
    if (error.response.status === 429) {
        Vue.swal({
            title: "เกิดข้อผิดพลาด",
            icon: "error",
            text: "เนื่องจากผู้ใช้ส่งคำขอมากเกินไปในระยะเวลาที่กำหนด (อาจเป็นข้อผิดพลาดจากปลั๊กอิน,DDos ,หรืออย่างอื่น) ซึ่งทางเซิร์ฟเวอร์จะให้คุณหยุดส่งคำขอ",
        });
    }
    return Promise.reject(error);
};

axios.defaults.baseURL = process.env.VUE_APP_URL || "http://104.248.159.136:8051";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.interceptors.response.use((response) => response, handleResponseError);

Vue.component("vg-loading", require("./components/LoadingBar.vue").default);
Vue.component('vue-timepicker', VueTimepicker);

window.axios = axios;
window._ = _;
window.dayjs = dayjs;
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");