import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import changePassword from "../views/ChangePassword.vue";
import Register from "../views/Register.vue";
import EditProfile from "../views/EditProfile.vue";
import users from "./users";
import Profile from "../views/Profile.vue";

import Overview from "../views/Overview.vue";
import Student from "../views/Student.vue";
import AddCase from "../views/add-case.vue";
import StudentCase from "../views/student-case";
import ReportStudent from "../views/report-student.vue";
import Report from "../views/Report.vue";
import InfoStudent from "../views/Info-student.vue";
import reportCase from "../views/report-case.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            auth: false,
        },
    },
    {
        path: "/changepassword",
        name: "ChangePassword",
        component: changePassword,
        meta: {
            auth: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
            auth: false,
        },
    },
    {
        path: "/edit/profile",
        name: "EditProfile",
        component: EditProfile,
        meta: {
            auth: true,
        },
    },
    {
        path: "/Profile",
        name: "Profile",
        component: Profile,
        meta: {
            auth: true,
        },
    },
    {
        path: "/",
        name: "overview",
        component: Overview,
        meta: {
            auth: true,
        },
    },
    {
        path: "/students",
        name: "students",
        component: Student,
        meta: {
            auth: true,
        },
    },
    {
        path: "/add-case",
        name: "add case",
        component: AddCase,
        meta: {
            auth: [99],
        },
    },
    {
        path: "/student/:student_id/student-cases",
        name: "students-case",
        component: StudentCase,
        meta: {
            auth: true,
        },
    },
    {
        path: "/student/:student_id/student-cases",
        name: "students-case",
        component: StudentCase,
        meta: {
            auth: true,
        },
    },
    {
        path: '/reports/:id',
        name: '/reports/id',
        component: reportCase,
        meta: {
            auth: true
        }
    },
    {
        path: '/students/:student_id/reports/create',
        name: '/students/student_id/reports/create',
        component: ReportStudent,
        // props: (route) => ({ query: route.query.caseId }),
        meta: {
            auth: true
        }
    },
    {
        path: '/students/:id',
        name: '/students/id',
        component: InfoStudent,
        meta: {
            auth: true
        }
    },
    {
        path: "/reports",
        name: "reports",
        component: Report,
        meta: {
            auth: true,
        },
    },
    ...users,
    {
        path: "/404",
        component: () =>
            import ("../views/404.vue"),
    },
    {
        path: "/403",
        component: () =>
            import ("../views/403.vue"),
    },

    {
        path: "*",
        redirect: '/404'
    },
];

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
});

export default router;